import React from 'react';
import { map, orderBy } from 'lodash';
import moment from 'moment';

import { dossierPageWrapper as coreDossierPageWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';
import { DossierPage as CoreDossierPage } from '@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';

import contactInformation from '../constants/contactInformation';
import documentDefinitionKeys from '../constants/documentDefinitionKeys.json';
import taskDefinitionKeys from '../constants/taskDefinitionKeys.json';

import contextType from '@skryv/core-react/src/services/contextTypes';

export function vrfDossierPageWrapper() {
  class vrfDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    information() {
      const dossierInformation = [];
      if(this.props.dossierDetails) {
        const verschijnsel = this.props.getFieldFromDocument('melding',['verschijnsel','selectedOptionLabel','nl']);
        dossierInformation.push({
          value: verschijnsel,
          label: 'Verschijnsel'
        });

        const postcodes = map(this.props.getFieldFromDocument('melding',['postcodes','elements']), (value, index) => {
          if(this.props.getFieldFromDocument('melding',['postcodes','elements',index,'postcode'])) {
            return this.props.getFieldFromDocument('melding',['postcodes','elements',index,'postcode']) + ' ' + '\n';
          } else {
            return '';
          }
        });
        dossierInformation.push({
          value: postcodes,
          label: 'Postcodes'
        });

        let startdatum = '';
        const startdatumValue = this.props.getFieldFromDocument('melding',['startdatum']);
        if(startdatumValue) {
          startdatum = moment(startdatumValue).format('DD-MM-yyyy');
        }
        dossierInformation.push({
          value: startdatum,
          label: 'Startdatum'
        });

        let einddatum = '';
        const einddatumValue = this.props.getFieldFromDocument('melding',['einddatum']);
        if(einddatumValue) {
          einddatum = moment(einddatumValue).format('DD-MM-yyyy');
        }
        dossierInformation.push({
          value: einddatum,
          label: 'Einddatum'
        });

        const beschrijving = this.props.getFieldFromDocument('melding',['beschrijving_van_de_schade','value']);
        dossierInformation.push({
          value: beschrijving,
          label: 'Beschrijving'
        });

        const bedrag = this.props.getFieldFromDocument('melding',['geraamde_bedrag_van_de_schade']);
        dossierInformation.push({
          value: bedrag,
          label: 'Geraamde bedrag'
        });
      }
      return dossierInformation;
    }

    downloads() {
      if (!this.props.dossierDetails) return [];

      // after submission, the request form can be downloaded in pdf here (does not contain links to attachments)
      const aanvraagDocument = this.props.getDocument(documentDefinitionKeys.AANVRAAG_DOCUMENT_KEY, true);
      const downloadableDocuments = !aanvraagDocument ? [] : [this.props.mapDocumentToPdfDownloadInfo(aanvraagDocument, 'Aanvraag', 'eloket_aanvraag')];
      // after submission, the request task can be viewed in read-only mode here
      const meldingAanvraagTask = this.props.getTask(taskDefinitionKeys.MELDING_TASK_KEY);
      const viewableTasks = (!meldingAanvraagTask || meldingAanvraagTask.active) ? [] : [this.props.mapTaskToViewDownloadInfo(meldingAanvraagTask, 'Melding')];

      return orderBy([...downloadableDocuments, ...viewableTasks], 'label');
    }

    notifications() {
      if (!this.props.dossierDetails) return [];

      const aanvraagTask = this.props.getTask(taskDefinitionKeys.MELDING_TASK_KEY);
      return (!aanvraagTask || aanvraagTask.active) ? [] : [{
        id: 'request_filed',
        title: this.context.gettext('Uw melding werd correct ontvangen'),
        message: `Uw melding werd ontvangen en kreeg volgend nummer: ${this.props.dossier.label}.<br />
          Indien deze melding tot een erkende ramp leidt, zult u een bericht ontvangen via mail.<br />
          Indien u nog een nieuwe melding wil ingeven, klik dan hierboven op 'Dashboard' en ga naar 'Doe een melding'.`
        ,
        type: notificationTypes.SUCCESS
      }];
    }

    render() {
      const propsToPass = {
        ...this.props,
        contactInformation,
        dossierInformation: this.information(),
        notifications: this.notifications(),
        shouldShowContactInformation: true,
        shouldShowDossierInformation: true,
        shouldShowDownloads: true,
        shouldShowNotifications: true
      };
      return <CoreDossierPage { ...propsToPass } />;
    }
  }

  return vrfDossierPage;
}

export default coreDossierPageWrapper(vrfDossierPageWrapper());
