import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CoreHeader from '@skryv/core-react-vo/src/components/layout/Header/Header';

import { loginUser } from '@skryv/core-react/src/core/store/actions/authentication';
import { fetchConfiguration } from '@skryv/core-react/src/core/store/actions/configuration';
import { isAuthenticated } from '@skryv/core-react/src/core/store/selectors/users';

import { postscribePromise } from '../services/postscribe';

import { fetchVrfConfiguration } from '../store/actions';
import { hasLoadedBurgerprofiel, selectBurgerprofielHeader } from '../store/selectors';

import contextType from '@skryv/core-react/src/services/contextTypes';

const reduxProps = {
  isAuthenticated: PropTypes.bool,
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielHeaderEmbedCode: PropTypes.string,
  loginUser: PropTypes.func,
  fetchConfiguration: PropTypes.func,
  fetchVrfConfiguration: PropTypes.func
};

const HEADER_ID = "header-script";
const HEADER_SELECTOR = `#${HEADER_ID}`;

class vrfHeader extends React.Component {
  static propTypes = reduxProps;
  static contextType = contextType;

  constructor(props, context) {
    super(props);

    props.loginUser();
    props.fetchConfiguration();
    props.fetchVrfConfiguration();

    this.state = {
      title: 'VLAAMS RAMPENFONDS - ',
      subtitle: context.gettext('e-Loket van het Vlaams Rampenfonds'),
      shouldShowProfile: false
    };
  }

  componentDidUpdate() {
    // the header script has already loaded or we are still loading the needed information, don't do anything
    if (!this.props.hasLoadedBurgerprofiel) return;

    if (this.hasRenderedHeader()) return;
    // we have all information to use the Mijn Burgerprofiel header
    if (this.props.isAuthenticated && this.props.burgerprofielHeaderEmbedCode) {
      this.loadMijnBurgerprofielHeader();
    }
    // otherwise we display the VO fallback header
    else {
      const voHeaderEmbedCode = '//widgets.vlaanderen.be/widget/live/7e3adf8027dc46a983fa42500ac3e1d4';
      postscribePromise(HEADER_SELECTOR, voHeaderEmbedCode)
        .then(() => this.setState({ shouldShowProfile: true }));
    }
  }

  hasRenderedHeader() {
    const headerElement = document.getElementById("header-script");
    return headerElement && headerElement.children.length > 0;
  }

  loadMijnBurgerprofielHeader() {
    const burgerprofielPolyfillSrc = 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js';
    const burgerprofielClientSrc = 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-client/dist/index.js';

    return Promise.all([postscribePromise(HEADER_SELECTOR, burgerprofielPolyfillSrc), postscribePromise(HEADER_SELECTOR, burgerprofielClientSrc)])
      .then(() => postscribePromise(HEADER_SELECTOR, this.props.burgerprofielHeaderEmbedCode))
      .then(() => {
        // configure the Mijn Burgerprofiel header
        window.vl.widget.client.capture((widget) => {
          widget.getExtension('citizen_profile.session').then((session) => {
            session.configure({
              active: this.props.isAuthenticated,
              endpoints: {
                loginUrl: '/api/login/frontoffice',
                logoutUrl: '/api/logout',
                switchCapacityUrl: '/api/wisselen-van-account',
              }
            });
          });
        });
      });
  }

  render() {
    return (
      <div>
        <CoreHeader { ...this.props }{ ...this.state }></CoreHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielHeaderEmbedCode: selectBurgerprofielHeader(state)
});

const mapDispatchToProps = {
  loginUser,
  fetchConfiguration,
  fetchVrfConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(vrfHeader);