import React from 'react';
import { get } from 'lodash';
import { generatePathStringForManipulator } from '@skryv/core-react/src/core/services/docmod/formRendererHelpers';
import contextType from '@skryv/core-react/src/services/contextTypes';

import Help from '@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/Help/Help';
import InlineHelp from '@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InlineHelp/InlineHelp';
import InlineErrors from '@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InlineErrors/InlineErrors';
import InputLabel from '@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InputLabel/InputLabel';

import propTypes from '@skryv/core-react-vo/src/components/form/services/editorComponentPropTypes';

import './InputFieldLegacy.scss';

export default class InputFieldLegacy extends React.Component {
  static propTypes = propTypes;
  static contextType = contextType;

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.shouldDisplayErrors = this.shouldDisplayErrors.bind(this);
    this.state = {
      isDirty: false
    };
  }

  onChange(event) {
    // this is the onChange event most input fields will use; if another one is needed, this function can be overwritten in the input field itself
    this.props.manipulator.value = event.target.value;
    this.setState({ isDirty: true });
  }

  shouldDisplayErrors() {
    // Errors should be displayed if there are any and there are more errors than the field being required
    // We show all errors regardless of the above if the field is dirty or the document is being reviewed
    const hasError = () => {
      return this.props.manipulator.errors.length > 0;
    };

    const hasOnlyRequiredError = () => {
      if (!this.props.manipulator) return false;
      return this.props.manipulator.errors.length === 1 && this.props.manipulator.errors[0] === 'required';
    };

    const isReviewing = () => {
      if (!(get(this.props, ['config', 'currentDocument']))) return false;
      return this.props.config.currentDocument.getIsReviewing();
    };

    const isDirty = () => {
      return this.state.isDirty;
    };

    return hasError() && (!hasOnlyRequiredError() || isReviewing() || isDirty());
  }

  content() {
    throw new Error('FORM: The content function should be overridden in the input field');
  }

  renderInlineInput() {
    return (
      <>
        <div className="input-component-wrapper">
          <div className="input-component-content">
            { this.content() }
          </div>
          <div className="input-component-content">
            <InputLabel htmlFor={ generatePathStringForManipulator(this.props.manipulator) } label={ this.props.component.label } isRequired={ this.props.manipulator.isRequired }></InputLabel>
          </div>
          { (this.props.component.help && !this.props.component.helpInLine) && <Help helpText={ this.props.component.help } /> }
        </div>
        { this.props.component.helpInLine && <InlineHelp helpText={ this.props.component.help }></InlineHelp> }
      </>
    );
  }

  renderDefaultInput() {
    return (
      <>
        <div className="input-component-wrapper">
          <InputLabel
            htmlFor={ generatePathStringForManipulator(this.props.manipulator) }
            label={ this.props.component.label }
            isRequired={ this.props.manipulator.isRequired } />
          { (this.props.component.help && !this.props.component.helpInLine) && <Help helpText={ this.props.component.help } /> }
        </div>
        { this.props.component.helpInLine && <InlineHelp helpText={ this.props.component.help }></InlineHelp> }
        { this.content() }
      </>
    );
  }

  render() {
    return (
      <div className="input-component" id={ 'editor-' + generatePathStringForManipulator(this.props.manipulator) }>
        { this.isInline ? this.renderInlineInput() : this.renderDefaultInput() }
        { this.shouldDisplayErrors() && <InlineErrors manipulator={ this.props.manipulator }></InlineErrors> }
      </div>
    );
  }
}
