import React from 'react';
import { get, orderBy } from 'lodash';
import moment from 'moment';

import { dossierPageWrapper as coreDossierPageWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';
import { DossierPage as CoreDossierPage } from '@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';

import contactInformation from '../constants/contactInformation';
import documentDefinitionKeys from '../constants/documentDefinitionKeys.json';
import taskDefinitionKeys from '../constants/taskDefinitionKeys.json';

import contextType from '@skryv/core-react/src/services/contextTypes';

export function vrfDossierPageWrapper() {
  class vrfDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    downloads() {
      if (!this.props.dossierDetails) return [];

      const downloadableCommunications = get(this.props, ['communications'], []).map(this.props.mapCommunicationToPdfDownloadInfo);
      // after submission, the request form can be downloaded in pdf here (does not contain links to attachments)
      const aanvraagDocument = this.props.getDocument(documentDefinitionKeys.AANVRAAG_DOCUMENT_KEY, true);
      const downloadableDocuments = !aanvraagDocument ? [] : [this.props.mapDocumentToPdfDownloadInfo(aanvraagDocument, 'Aanvraag', 'eloket_aanvraag')];
      // after submission, the request task can be viewed in read-only mode here
      const aanvraagTask = this.props.getTask(taskDefinitionKeys.AANVRAAG_TASK_KEY);
      const viewableTasks = (!aanvraagTask || aanvraagTask.active) ? [] : [this.props.mapTaskToViewDownloadInfo(aanvraagTask, 'Aanvraag')];

      return orderBy([...downloadableCommunications, ...downloadableDocuments, ...viewableTasks], 'label');
    }

    notifications() {
      if (!this.props.dossierDetails) return [];

      const aanvraagTask = this.props.getTask(taskDefinitionKeys.AANVRAAG_TASK_KEY);
      return (!aanvraagTask || aanvraagTask.active) ? [] : [{
        id: 'request_filed',
        title: this.context.gettext('Your request was correctly received'),
        message: `${this.context.gettext('Your request was attributed the following dossier number:')} ${this.props.dossier.label}. ${this.context.gettext('Please make sure to always mention this dossier number on future communications')}.`,
        type: notificationTypes.SUCCESS
      }];
    }

    render() {
      const propsToPass = {
        ...this.props,
        contactInformation,
        dossierInformation: [{
          value: this.props.dossier.label,
          label: 'Dossierlabel'
        }, {
          value: moment(this.props.dossier.updatedAt).format('LL'),
          label: 'Laatste wijziging'
        }],
        downloads: this.downloads(),
        notifications: this.notifications(),
        shouldShowContactInformation: true,
        shouldShowDossierInformation: true,
        shouldShowDownloads: true,
        shouldShowNotifications: true
      };
      return <CoreDossierPage { ...propsToPass } />;
    }
  }

  return vrfDossierPage;
}

export default coreDossierPageWrapper(vrfDossierPageWrapper());
