import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import CoreDashboardPage from '@skryv/core-react-vo/src/pages/Dashboard/DashboardPage';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';

import { fetchThereIsAnActiveDisaster, fetchVrfProfile } from '../store/actions';
import { selectThereIsAnActiveDisaster, selectVrfProfile } from '../store/selectors';

import localStorage from '../services/localStorage';

import contactInformation from '../constants/contactInformation';
import dossierDefinitionKeys from '../constants/dossierDefinitionKeys';
import localStorageKeys from '../constants/localStorageKeys';

import contextType from '@skryv/core-react/src/services/contextTypes';

const reduxProps = {
  allowedToFileARequest: PropTypes.bool,
  vrfProfile: PropTypes.shape({
    kboNumber: PropTypes.string
  }),
  fetchThereIsAnActiveDisaster: PropTypes.func,
  fetchVrfProfile: PropTypes.func
};

class vrfDashboard extends React.Component {
  static propTypes = reduxProps;
  static contextType = contextType;

  constructor(props) {
    super(props);
    this.props.fetchThereIsAnActiveDisaster();
    this.props.fetchVrfProfile();

    // The kboNumber is stored in local storage upon login to later check if the user is logged in under the context of the same kboNumber
    // See https://skryvdev.atlassian.net/wiki/spaces/VRF/pages/870252573/Links+from+Company+Portal+Ondernemingsloket
    this.state = {
      ondernemingsLoketKboNumber: localStorage.get(localStorageKeys.ONDERNEMINGSLOKET_KBONUMBER_KEY)
    };
    localStorage.remove(localStorageKeys.ONDERNEMINGSLOKET_KBONUMBER_KEY);
  }

  isKboNumberValidForCurrentScope() {
    return get(this.props,['vrfProfile','kboNumber']) === get(this.state,['ondernemingsLoketKboNumber']);
  }

  render() {

    let notificationWhenKBO = [];
    if (this.props.vrfProfile && this.state.ondernemingsLoketKboNumber && !this.isKboNumberValidForCurrentScope()) {
      notificationWhenKBO = this.props.vrfProfile.kboNumber ? [{
        id: 'kbo',
        title: 'Opgelet!',
        message: `U bent op dit moment ingelogd voor het bedrijf met kbo-nummer ${this.props.vrfProfile.kboNumber}, maar wilde inloggen voor het bedrijf met kbo-nummer ${this.state.ondernemingsLoketKboNumber}
          Gelieve opnieuw in te loggen om de dossiers die gelinkt zijn aan het bedrijf met kbo-nummer ${ this.state.ondernemingsLoketKboNumber} te behandelen.`,
        type: notificationTypes.WARNING
      }] : [{
        id: 'no_kbo',
        title: 'Opgelet!',
        message: `U bent op dit moment ingelogd als burger, maar wilde inloggen voor het bedrijf met kbo-nummer ${this.state.ondernemingsLoketKboNumber}
          Gelieve opnieuw in te loggen om de dossiers die gelinkt zijn aan het bedrijf met kbo-nummer ${ this.state.ondernemingsLoketKboNumber} te behandelen.`,
        type: notificationTypes.WARNING
      }];
    }
    const notificationWhenNoActiveDisaster = !this.props.allowedToFileARequest ? [{
      id: 'no_active_disasters',
      message: "Er zijn op dit moment geen erkende rampen waarvoor u een vergoeding kunt aanvragen. <br />Indien u schade wil melden voor een niet-erkende ramp, ga dan hieronder naar 'Melding van schade'.",
      type: notificationTypes.INFO
    }] : [];

    const propsToPass = {
      ...this.props,
      title: this.context.gettext('e-Loket van het Vlaams Rampenfonds'),
      contactInformation,
      myDossierColumns: [
        {
          key: 'label',
          text: 'Dossier',
        },
        {
          key: 'createdAt',
          text: this.context.gettext('Created at'),
          formatter: (cell) => { // both cell and formatter are available
            return moment(cell).format('DD-MM-YYYY');
          }
        }
      ],
      createDossierInformation: this.props.allowedToFileARequest ? [{
        dosdefKey: dossierDefinitionKeys.MELDING_DOSSIER,
        buttonLabels: {
          active: 'Doe een melding'
        }
      },{
        dosdefKey: dossierDefinitionKeys.RAMPENFONDS_DOSSIER,
        buttonLabels: {
          active: 'Vraag een vergoeding aan'
        }
      }] : [{
        dosdefKey: dossierDefinitionKeys.MELDING_DOSSIER,
        buttonLabels: {
          active: 'Dien een melding van schade in'
        }
      }],
      notifications: [...notificationWhenKBO, ...notificationWhenNoActiveDisaster],
      goToDossierInformationPageFirst: true
    };
    return (<CoreDashboardPage { ...propsToPass }></CoreDashboardPage>);
  }
}

const mapStateToProps = (state) => ({
  allowedToFileARequest: selectThereIsAnActiveDisaster(state) || false,
  vrfProfile: selectVrfProfile(state)
});

const mapDispatchToProps = {
  fetchThereIsAnActiveDisaster,
  fetchVrfProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(vrfDashboard);
