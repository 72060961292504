import React from 'react';

import CoreDossierInfoPage from '@skryv/core-react-vo/src/components/dossier/DossierInfoPage/DossierInfoPage';
import { dossierInfoPageWrapper as coreDossierInfoPageWrapper } from '@skryv/core-react/src/components/dossier/DossierInfoPage/DossierInfoPage';


import dosdefKeys from '../constants/dossierDefinitionKeys';
import PropTypes from 'prop-types';

export default class vrfDossierInfo extends React.Component {
  constructor(props) {
    super(props);

    // set default props
    this.state = {
      getCreationModeKeyForDosdef: () => 'eLoket'
    };
  }

  render() {
    const CoreWrapperWithNewDefaultProps = coreDossierInfoPageWrapper(vrfDossierInfoWrapper());
    const componentsToPass = {
      ...this.props,
      ...this.state
    };
    return (<CoreWrapperWithNewDefaultProps { ...componentsToPass }></CoreWrapperWithNewDefaultProps>);
  }
}

function vrfDossierInfoWrapper() {
  class vrfDossierInfoInternal extends React.Component {
    static propTypes = {
      createNewDossier: PropTypes.func,
      dosdef: PropTypes.object
    }

    constructor(props) {
      super(props);

      // set props that depend on internal props
      if(this.props.dosdef.key === dosdefKeys.MELDING_DOSSIER) {
        this.state = {
          stepInformation: [
            {
              title: 'Stap 1: Wat is een melding van schade?',
              paragraphs: [
                'Wenst u een uitzonderlijk weersverschijnsel te laten erkennen als ramp, meld uw schade aan door op de knop "Doe een melding van schade" te klikken.\nHet Vlaams Rampenfonds zal het door u gevraagde natuurverschijnsel laten onderzoeken. De Vlaamse Regering zal beslissen over de erkenning in toepassing van de geldende wetgeving.\nDeze melding geldt niet als een aanvraag tot effectieve tegemoetkoming. De aanvraag tot tegemoetkoming voor de schade kan pas ingediend worden nadat het eventuele erkenningsbesluit gepubliceerd is in het Belgische Staatsblad.\n'
              ]
            },
            {
              title: 'Stap 2: Doe een melding van schade',
              paragraphs: [
                'Klik op de knop "Doe een melding van schade" om uw melding in te geven.'
              ],
              buttonInfo: {
                labels: {
                  active: 'Doe een melding van schade'
                },
                action: () => this.props.createNewDossier()
              }
            },
            {
              title: 'Stap 3: Vervolgstappen',
              paragraphs: [
                'Indien deze melding tot een erkenning als ramp leidt, zal u een e-mail ontvangen voor de volgende stappen die u moet zetten om  een vergoeding aan te vragen.'
              ]
            }
          ]
        };
      }

      if(this.props.dosdef.key === dosdefKeys.RAMPENFONDS_DOSSIER) {
        this.state = {
          stepInformation: [
            {
              title: 'Stap 1: Wat is een aanvraag tot vergoeding?',
              paragraphs: [
                'Heeft u schade geleden naar aanleiding van een erkende ramp en werd de gemeente of stad waar deze schade werd geleden opgenomen in het erkenningsbesluit, dan kan u hier uw aanvraag tot effectieve vergoeding van uw schade indienen.\nU kan uw aanvraag indienen binnen 3 maand volgend op de maand van de publicatie van het erkenningsbesluit in het Belgisch Staatsblad. Het e-loket van het Vlaams Rampenfonds blijft tijdens deze periode hiertoe beschikbaar.\nLet op: u kan enkel een vergoeding aanvragen als de ramp werd erkend. Indien u een melding wil doen van schade veroorzaakt door een niet erkend weerschijnsel, dan klikt u op “Doe een melding van schade”. Het Vlaams Rampenfonds zal op basis van deze melding de mogelijkheid tot erkenning onderzoeken.'
              ]
            },
            {
              title: 'Stap 2: Komt u in aanmerking voor een vergoeding?',
              paragraphs: [
                  'Enkel schade aan goederen die niet verzekerbaar zijn door een brandpolis eenvoudige risico’s kan voor een vergoeding in aanmerking komen.\nDe goederen, die schade hebben geleden en waarvoor een brandpolis eenvoudige risico’s werd afgesloten, komen niet voor een tussenkomst in aanmerking, ook al ontving u geen vergoeding van uw brandverzekeraar omdat de goederen in de polis uitgesloten worden van de verzekeringswaarborg.\nHebt u schade geleden aan teelten en/of niet binnengehaalde oogsten, dan komt enkel de niet verzekerde schade in aanmerking als u een brede weersverzekering hebt afgesloten voor minstens 25% van uw totaal teeltareaal. Onder uw totaal teeltareaal wordt verstaan: de som van de perceelsoppervlaktes van de hoofdteelten in eigen gebruik op 31 mei, zoals geregistreerd in uw verzamelaanvraag van het jaar van de ramp.\nKomt u in aanmerking, dan kan u uw aanvraag tot effectieve vergoeding van de schade indienen door op de knop "Doe een aanvraag tot vergoeding" te klikken.'
              ]
            },
            {
              title: 'Stap 3: Doe een aanvraag tot vergoeding',
              paragraphs: [
                  'Klik op de knop "Doe een aanvraag tot vergoeding" om uw vergoedingsdossier aan te maken.'
              ],
              buttonInfo: {
                labels: {
                  active: 'Doe een aanvraag tot vergoeding'
                },
                action: () => this.props.createNewDossier()
              }
            },
            {
              title: 'Stap 4: Vervolgstappen',
              paragraphs: [
              'Na de ontvangst van uw aanvraag ontvangt u een dossiernummer. Gelieve dit dossiernummer steeds te vermelden wanneer u contact opneemt met het Vlaams Rampenfonds. U wordt via mail of brief op de hoogte gehouden van de stand van uw dossier en van de uitbetaling van uw tegemoetkoming.'
              ]
            }
          ]
        };
      }

    }

    render() {
      const componentsToPass = {
        ...this.props,
        ...this.state
      };
      return (<CoreDossierInfoPage { ...componentsToPass }></CoreDossierInfoPage>);
    }
  }

  return vrfDossierInfoInternal;
}