import React from 'react';
import moment from 'moment';
import { isActiveDisasterAtGivenTime } from '../../store/selectors';
import { generatePathStringForManipulator } from '@skryv/core-react/src/core/services/docmod/formRendererHelpers';
import InputFieldLegacy from './InputFieldLegacy/InputFieldLegacy';
import contextType from '@skryv/core-react/src/services/contextTypes';
/*
 * Custom component that filters the disasters in the referencelist: only disasters that are available for a request are shown
 */
export default class vrfDisasterReferenceList extends InputFieldLegacy {
  static name = 'vrfDisasterReferenceList';
  static contextType = contextType;

  constructor(props) {
    super(props);

    const getReferenceListQuery = this.getReferenceListQuery.bind(this);
    const setReferenceListQuery = this.setReferenceListQuery.bind(this);

    this.state = {
      isOpen: false,
      results: [],
      isLoading: false,
      inputValue: this.props.manipulator.getValue() || '',
      arrowCounter: -1,
      wrapperRef: React.createRef(),
      dummyErrors: [],
      get referenceListQuery() {
        return getReferenceListQuery();
      },
      set referenceListQuery(v) {
        setReferenceListQuery(v);
      }
    };

    // when the manipulator value gets changed from outside of this component (so not by the user via this input field),
    // we need need to update the inputValue to make sure the user sees the new value
    this.props.manipulator.state.onSetMatch(() => {
      this.setState({ inputValue: this.props.manipulator.getValue() });
    });
    this.props.manipulator.state.onClear(() => {
      this.setState({ inputValue: '' });
    });

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.wrapperRef && !this.state.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  getReferenceListQuery() {
    if (this.props.manipulator.read()) {
      const query = this.props.manipulator.getQuery();
      if (typeof query === 'object') {
        return this.renderResult(query);
      } else if (typeof query === 'string') {
        return query;
      }
    }
    return '';
  }

  setReferenceListQuery(v) {
    if (!v) {
      v = '';
    }
    this.props.manipulator.inputQuery(v);
  }

  onFocus() {
    this.filterResults();
    this.setState({ isOpen: true });
  }

  onChange(event) {
    this.setState({ inputValue: event.target.value }, () => this.filterResults());
  }

  filterResults() {
    this.props.manipulator.state.autocompleteFor(this.state.referenceListQuery.toLowerCase(), (error, result) => {
      if (error === undefined) {
        // this referencelist contains a list of disasters, we want to return the active disasters only
        const now = moment.utc();
        const results = result.filter((disasterInfo) => isActiveDisasterAtGivenTime(disasterInfo, now));

        if (results.length === 0) this.setState({
          results,
          dummyErrors: [
            this.context.gettext('At the moment, there are no recognised disasters you can file a request for')
          ]
        });
        else this.setState({ results, dummyErrors: [] });
      }
      else {
        this.setState({
          results: [],
          dummyErrors: [
            this.context.gettext('The list with recognised disasters cannot be fetched. Try again later.')
          ]
        });
      }
    });
  }

  shouldDisplayErrors() {
    return super.shouldDisplayErrors() || this.state.dummyErrors.length !== 0;
  }

  errorMessages() {
    return [... super.errorMessages(), ... this.state.dummyErrors];
  }

  setResult = (result) => {
    if(!result) {
      this.props.manipulator.state.clear();
    }
    else {
      this.props.manipulator.state.becomeMatch(result);
    }
    this.setState({ inputValue: this.props.manipulator.getValue(), isOpen: false });
  }

  onKeyUp(event) {
    this.filterResults();
    // on arrow up
    if(event.keyCode === 38) {
      if (this.state.arrowCounter > 0) {
        this.setState({ arrowCounter: this.state.arrowCounter - 1 });
      }
    }

    // on arrow down
    if (event.keyCode === 40) {
      if (this.state.arrowCounter < this.state.results.length) {
        this.setState({ arrowCounter: this.state.arrowCounter + 1 });
      }
    }

    // on enter
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.setResult(this.state.results[this.state.arrowCounter]);
      this.setState({ arrowCounter: -1 });
    }
  }

  renderResult(option) {
    return this.props.manipulator.state.renderMatch(option);
  }

  content() {
    const isRequired = Boolean(this.props.manipulator.isRequired);
    const editableInput = (
      <div className="reference-list-input" ref={ this.state.wrapperRef }>
        <input
          className={ `${ this.shouldDisplayErrors() ? 'vl-input-field--error' : '' } vl-input-field vl-input-field--block` }
          id={ generatePathStringForManipulator(this.props.manipulator) + '-vl-select' }
          name={ generatePathStringForManipulator(this.props.manipulator) + '-vl-select' }
          type="text"
          onKeyUp={ this.onKeyUp.bind(this) }
          onFocus={ this.onFocus.bind(this) }
          onChange={ this.onChange.bind(this) }
          value={ this.state.inputValue }
          aria-required={ isRequired }
          aria-invalid={ this.shouldDisplayErrors() }
        />
        { this.state.isOpen && <ul id="autocomplete-results" className="vl-icon-list skr-reflist-result reference-list">
          { this.state.results.slice(0, 10).map( (result, i) =>
            <li key={ i } className={ `${this.state.arrowCounter === i ? 'is-active' : ''} vl-icon-list__item` }>
              <div
                role="button"
                tabIndex="0"
                onKeyPress={ this.setResult.bind(this, result) }
                onClick={ this.setResult.bind(this, result) }>
                <label className="vl-form__label">
                  { this.renderResult(result) }
                </label>
              </div>
            </li>
          ) }
        </ul>
        }
      </div>);

    const readOnlyInput = (
      this.props.manipulator.read() ? <p className="vl-form__annotation read-only">{ this.renderResult(this.props.manipulator.read()) }</p>
        : <p className="vl-form__annotation">{ this.context.gettext('n/a') }</p>
    );

    return (
      <div>
        { !this.props.manipulator.readOnly && editableInput }
        { this.props.manipulator.readOnly && readOnlyInput }
      </div>
    );
  }
}