import { fetchReferenceList } from '@skryv/core-react/src/core/store/actions/referencelists';
import referenceListKeys from '../constants/referenceListKeys';

export const FETCH_VRF_PROFILE = 'FETCH_VRF_PROFILE';
export const FETCH_VRF_APPLICATION_CONFIGURATION = 'FETCH_VRF_APPLICATION_CONFIGURATION';
export const FETCH_LV_PERCEEL_REFLIST = 'FETCH_LV_PERCEEL_REFLIST';

export const FETCH_VRF_PROFILE_RESPONSE = `${FETCH_VRF_PROFILE}/RESPONSE`;
export const FETCH_VRF_APPLICATION_CONFIGURATION_RESPONSE = `${FETCH_VRF_APPLICATION_CONFIGURATION}/RESPONSE`;
export const FETCH_VRF_APPLICATION_CONFIGURATION_REQUEST = `${FETCH_VRF_APPLICATION_CONFIGURATION}/REQUEST`;
export const FETCH_VRF_APPLICATION_CONFIGURATION_ERROR = `${FETCH_VRF_APPLICATION_CONFIGURATION}/ERROR`;

export const fetchThereIsAnActiveDisaster = () => fetchReferenceList(referenceListKeys.RAMPEN_REFLIST);

export const fetchVrfProfile = () => {
  return {
    type: 'FETCH_VRF_PROFILE',
    api: {
      url: 'api/vrf/profile',
      method: 'GET',
    }
  };
};

export const fetchVrfConfiguration = () => {
  return {
    type: 'FETCH_VRF_APPLICATION_CONFIGURATION',
    api: {
      url: '/api/vrf/configuration',
      method: 'GET',
      cache: true
    }
  };
};

export function fetchLVPerceelReflist(dossierId, year, disasterCode) {
  return {
    type: 'FETCH_LV_PERCEEL_REFLIST',
    api: {
      url: `api/dossiers/${dossierId}/lv/eloket?year=${year}&disasterCode=${disasterCode}`,
      method: 'GET'
    }
  };
}