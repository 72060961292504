import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { postscribePromise } from '../services/postscribe';

import { fetchVrfConfiguration } from '../store/actions';
import { hasLoadedBurgerprofiel, selectBurgerprofielFooter } from '../store/selectors';

const reduxProps = {
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielFooterEmbedCode: PropTypes.string,
  fetchVrfConfiguration: PropTypes.func
};

class vrfFooter extends React.Component {
  static propTypes = reduxProps;
  constructor(props) {
    super(props);
    props.fetchVrfConfiguration();

    this.state = {
      hasInitializedFooterScript: false,
      footerSelector: '#footer-script'
    };
  }

  componentDidUpdate() {
    // the footer script has already loaded or we are still loading the needed information, don't do anything
    if (this.state.hasInitializedFooterScript || !this.props.hasLoadedBurgerprofiel) return;

    // we have all information to use the Mijn Burgerprofiel footer
    if (this.props.burgerprofielFooterEmbedCode) {
      const burgerprofielPolyfillSrc = 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js';

      return postscribePromise(this.state.footerSelector,burgerprofielPolyfillSrc)
        .then(() => postscribePromise(this.state.footerSelector, this.props.burgerprofielFooterEmbedCode ))
        .then(() => this.setState({ hasInitializedFooterScript: true }));
    }
    // otherwise we display the VO fallback footer
    else {
      const voFooterEmbedCode = '//widgets.vlaanderen.be/widget/live/43e4392ff9984e6ea8b9ab6ee86e2840';
      postscribePromise(this.state.footerSelector,voFooterEmbedCode)
        .then(() => this.setState({ hasInitializedFooterScript: true }));
    }
  }

  render() {
    return (<div id="footer-script"></div>);
  }
}

const mapStateToProps = (state) => ({
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielFooterEmbedCode: selectBurgerprofielFooter(state)
});

const mapDispatchToProps = {
  fetchVrfConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(vrfFooter);