import React from "react";
import {
  SkryvFrontOfficeApp,
  navigation,
  form,
  translation,
} from "@skryv/core-react";

import "@skryv/core-react-vo";
import { registerDefaultRouting } from "@skryv/core-react-vo/src/config/routing";
import { registerDefaultTranslations } from "@skryv/core-react-vo/src/config/translations";

import { layoutNames } from "@skryv/core-react/src/config/layouts";
import { pageNames } from "@skryv/core-react/src/config/pages";
import { isInIframe } from "@skryv/core-react/src/core/util/iframe-utilities";

import vrfStore from "./customizations/store";
import { fetchLVPerceelReflist } from "./customizations/store/actions";

import VrfHeader from "./customizations/components/vrfHeader";
import VrfFooter from "./customizations/components/vrfFooter";
import vrfDashboard from "./customizations/components/vrfDashboard";
import vrfDossier from "./customizations/components/vrfDossier";
import vrfDossierInfo from "./customizations/components/vrfDossierInfo";

import vrfPerceel from "./customizations/components/form/vrfPerceel";
import vrfNietPerceel from "./customizations/components/form/vrfNietPerceel";
import vrfPersoon from "./customizations/components/form/vrfPersoon";
import vrfDisasterReferenceList from "./customizations/components/form/vrfDisasterReferenceList";

import translations from "../translations";

class VRFApp extends SkryvFrontOfficeApp {
  constructor() {
    super(vrfStore);
  }
}

const vrfNavigation = registerDefaultRouting(navigation)
  .layout(layoutNames.FRONTOFFICE, {
    extend: true,
    views: {
      header: function Header(props) {
        if (isInIframe) return null;
        return <VrfHeader {...props} />;
      },
      footer: function Footer(props) {
        if (isInIframe) return null;
        return <VrfFooter {...props} />;
      },
    },
  })
  .page(pageNames.DASHBOARD, {
    extend: true,
    views: {
      content: vrfDashboard,
    },
  })
  .page(pageNames.DOSSIER, {
    extend: true,
    views: {
      content: vrfDossier,
    },
  })
  .page(pageNames.DOSSIER_INFO, {
    extend: true,
    views: {
      content: vrfDossierInfo,
    },
  });
const vrfTranslations = registerDefaultTranslations(translation)
  .locale("nl_BE")
  .add("nl_BE", translations.nl_BE);

const customLVRequest = (dossierId) => (year, disasterCode) => (
  baseUrl,
  reflistName,
  cb
) => {
  if (!year || !disasterCode) {
    console.error(
      "No year or disaster code present; could not fetch L&V perceel referencelist"
    );
    return new Promise((resolve) => resolve([]));
  }

  return vrfStore
    .dispatch(fetchLVPerceelReflist(dossierId, year, disasterCode))
    .then((referencelist) => cb(undefined, referencelist.api.response.data))
    .catch((error) => cb(error, undefined));
};

const vrfForm = form.setFormConfig((context) => ({
  showSectionWizard: true,
  customRequests: {
    fetchLVReferencelist: customLVRequest(context.dossierId),
  },
  customComponents: {
    vrfDisasterReferenceList,
    vrfPerceel,
    vrfNietPerceel,
    vrfPersoon,
  },
}));

VRFApp.createSkryvApp()
  .customize(vrfNavigation)
  .customize(vrfTranslations)
  .customize(vrfForm)
  .initialize(document.getElementById("root"));
