import React from 'react';

import { generatePathStringForManipulator } from '@skryv/core-react/src/core/services/docmod/formRendererHelpers';

import InputField from '@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField';
import EditorComponent from '@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent';
import propTypes from '@skryv/core-react-vo/src/components/form/services/editorComponentPropTypes';

import './vrfForm.scss';

/*
 * Custom component that visualizes all fields in the vrfPersoon fieldset in a compact way (next to each other)
*/
function VrfPersoon({ manipulator, component, config }) {
  const gridColumns = [ 9,9 ];
  return (
    <InputField
      id={ generatePathStringForManipulator(manipulator) }
      label={ component.label }>
      <div className="skr-fieldset vl-grid vl-grid--align-start vrf-compact-fieldset">
        { component.components.map( (nested, i) => (
          <EditorComponent
            key={ generatePathStringForManipulator(nested.nestedManipulator(manipulator)) + i }
            component={ nested }
            manipulator={ nested.nestedManipulator(manipulator) }
            config={ config }
            className={ `vl-col--${gridColumns[i]}-12` }/>
        ))
        }
      </div>
    </InputField>
  );
}
VrfPersoon.propTypes = propTypes;
export default VrfPersoon;
