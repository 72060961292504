export class LocalStorage {
  static prefix = 'rampenfonds';

  static getItemKey = (key) => {
    return `${LocalStorage.prefix}/${key}`;
  }

  set(key, value) {
    const itemKey = LocalStorage.getItemKey(key);

    try {
      window.localStorage.setItem(itemKey, JSON.stringify(value));
    } catch (err) {
      console.error(`Could not set value ${value}`);
      console.error(err);
    }
  }

  get(key) {
    const itemKey = LocalStorage.getItemKey(key);

    try {
      return JSON.parse(window.localStorage.getItem(itemKey));
    } catch (err) {
      console.error(`Could not get value with ${key}`);
      console.error(err);
    }
  }

  remove(key) {
    const itemKey = LocalStorage.getItemKey(key);
    if (window.localStorage.getItem(itemKey)) window.localStorage.removeItem(LocalStorage.getItemKey(key));
  }
}

export default new LocalStorage();