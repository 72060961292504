import VrfPerceel from './vrfPerceel';

/*
 * Custom component that
 * - visualizes all fields in the VrfPerceel fieldset in a compact way (next to each other)
 * - fetches a custom referencelist, based on the selected disaster
 * - enriches the content of some fields based on the selected perceel
 */
export default class VrfNietPerceel extends VrfPerceel {
  static name = 'VrfNietPerceel';
  constructor(props) {
    super(props);
    this.gridColumns = [ 2,4,2,2,2 ];
  }

  enrichRelatedFields(match) {
    const { teelt } = this.props.manipulator.propertyManipulators;
    // only enrich these fields if they do not have a value yet to avoid changing manually overwritten values
    if(!teelt.state.currentKey()) {
      teelt.state.becomeKey(match.cropName);
    }
  }

  clearRelatedFields() {
    const { teelt } = this.props.manipulator.propertyManipulators;
    // only clear these fields if they have a value
    if(teelt.state.currentKey()) {
      teelt.state.clear();
    }
  }
}