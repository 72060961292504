import moment from 'moment';
import { get,isEmpty,reduce } from 'lodash';

import { selectReferencelist } from '@skryv/core-react/src/core/store/selectors/referencelists';

import referenceListKeys from '../constants/referenceListKeys';

// each disaster has a start date and an end date; we return true for the disasters where start date <= now <= end date, return true
export function isActiveDisasterAtGivenTime (disasterInfo, givenTime) {
  const start = moment.utc(get(disasterInfo,'startdatum_aanvraag'), 'DD-MM-YYYY').add(-2, 'hours');
  const end = moment.utc(get(disasterInfo,'einddatum_aanvraag'), 'DD-MM-YYYY').add(22, 'hours');
  if(start.isAfter(end)) {
    console.error('There seems to be an error in the referencelists for disasters: startdatum_aanvraag is after einddatum_aanvraag '
      + 'for the disaster with the following description: ' + disasterInfo.omschrijving);
    return false;
  }
  return start.isSameOrBefore(givenTime) && end.isSameOrAfter(givenTime);
}

export function selectThereIsAnActiveDisaster(state) {
  const disasters = selectReferencelist(state,referenceListKeys.RAMPEN_REFLIST);
  const now = moment.utc();
  return reduce(disasters,
    (acc,disasterInfo) => acc || isActiveDisasterAtGivenTime(disasterInfo,now),false);
}

export function selectVrfProfile(state) {
  return get(state,['vrfProfile']);
}

export function selectBurgerprofielHeader(state) {
  return get(state,['vrfConfiguration','configuration','burgerprofiel','headerEmbedCode']);
}

export function selectBurgerprofielFooter(state) {
  return get(state,['vrfConfiguration','configuration','burgerprofiel','footerEmbedCode']);
}

export function hasLoadedBurgerprofiel(state) {
  return !get(state,['vrfConfiguration','loading']) && !isEmpty(get(state,['vrfConfiguration','configuration']));
}