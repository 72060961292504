import React from 'react';
import moment from 'moment';
import { get } from 'lodash';

import { generatePathStringForManipulator } from '@skryv/core-react/src/core/services/docmod/formRendererHelpers';

import InputFieldLegacy from './InputFieldLegacy/InputFieldLegacy';
import EditorComponent from '@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent';

import './vrfForm.scss';
import './vrfPerceel.scss';

/*
 * Custom component that
 * - visualizes all fields in the VrfPerceel fieldset in a compact way (next to each other)
 * - fetches a custom referencelist, based on the selected disaster
 * - enriches the content of some fields based on the selected perceel
 */
export default class VrfPerceel extends InputFieldLegacy {
  static name = 'VrfPerceel';

  constructor(props) {
    super(props);
    this.gridColumns = [ 4,4,2,2 ];

    const { perceel } = props.manipulator.propertyManipulators;

    // load a specific referencelist for the perceel field, based on the year and the code of the disaster
    const year = moment(get(props,['manipulator','computedExpressions','ramp_startdatum']),'DD-MM-YYYY').year();
    const disasterCode = get(props,['manipulator','computedExpressions','ramp_code']);
    perceel.loadReferencelistWithSpecificRequest(props.config.customRequests.fetchLVReferencelist(year,disasterCode));

    // if perceel field is selected, enrich other fields appropriately
    perceel.state.onSetMatch(this.enrichRelatedFields.bind(this));

    // if the perceel field is emptied, also empty the related fields
    perceel.state.onClear(this.clearRelatedFields.bind(this));
  }

  enrichRelatedFields(match) {
    const { hectare, teelt } = this.props.manipulator.propertyManipulators;
    // only enrich these fields if they do not have a value yet to avoid changing manually overwritten values
    if(!hectare.value) {
      hectare.value = Number(match.declaredArea); // make sure we pass a number
    }
    if(!teelt.state.currentKey()) {
      teelt.state.becomeKey(match.cropName);
    }
  }

  clearRelatedFields() {
    const { hectare, teelt } = this.props.manipulator.propertyManipulators;
    // only clear these fields if they have a value
    if(hectare.value) {
      hectare.clear();
      hectare.value = Number(''); // make sure we pass a number; we need to set the value so the user sees the field is cleared
    }
    if(teelt.state.currentKey()) {
      teelt.state.clear();
    }
  }

  content() {
    return (
      <div className="skr-fieldset vl-grid vl-grid--align-start vrf-compact-fieldset">
        { this.props.component.components.map( (nested, i) => (
          <EditorComponent
            key={ generatePathStringForManipulator(nested.nestedManipulator(this.props.manipulator)) + i }
            component={ nested }
            manipulator={ nested.nestedManipulator(this.props.manipulator) }
            config={ this.props.config }
            className={ `vl-col--${this.gridColumns[i]}-12` }
            />
        ))
        }
      </div>
    );
  }
}
