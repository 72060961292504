import immutable from 'seamless-immutable';

import * as vrfActions from './actions';

export const vrfProfile = (state = immutable({}), action) => {
  if (action.type === vrfActions.FETCH_VRF_PROFILE_RESPONSE) {
    return immutable(action.response.data);
  }
  return state;
};

export const vrfConfiguration = (state = immutable({ loading: false }), action) => {
  if (action.type === vrfActions.FETCH_VRF_APPLICATION_CONFIGURATION_RESPONSE) {
    return immutable({ loading: false, configuration: action.response.data });
  }
  else if (action.type === vrfActions.FETCH_VRF_APPLICATION_CONFIGURATION_REQUEST) {
    return immutable({ loading: true });
  }
  else if (action.type === vrfActions.FETCH_VRF_APPLICATION_CONFIGURATION_ERROR) {
    return immutable({ loading: false });
  }
  return state;
};