import React from 'react';
import get from 'lodash/get';

import { dossierPageWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';


import VrfAanvraagDossier from './vrfAanvraagDossier';
import VrfMeldingDossier from './vrfMeldingDossier';
import dosdefKeys from '../constants/dossierDefinitionKeys';

export class vrfDossier extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;

  render() {
    const dossierDefinitionName = get(this.props.dossierDetails, ['dossier', 'dossierDefinition', 'key'], '');
    if (dossierDefinitionName === dosdefKeys.RAMPENFONDS_DOSSIER) {
      return <VrfAanvraagDossier { ...this.props } />;
    }
    if (dossierDefinitionName === dosdefKeys.MELDING_DOSSIER) {
      return <VrfMeldingDossier { ...this.props } />;
    }

    return null;
  }
}

export default dossierPageWrapper(vrfDossier);
